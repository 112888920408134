<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/photos">Photos</router-link> |
    <router-link to="/floor">Floor Plan</router-link>
  </nav>
  <router-view/>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/photos">Photos</router-link> |
    <router-link to="/floor">Floor Plan</router-link>
  </nav>
</template>

<style lang="scss">
* {
  box-sizing: border-box;
}

#app {
  width: 60rem;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, button, input, select, textarea {
  font-family: 'Source Sans 3', sans-serif;
  font-size: 20px;
  color: #051F54;
  line-height: 1.4;
}


img {
  width: 100%;
  height: auto;
  margin: 5px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
